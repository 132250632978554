import React, { useEffect, useState, useRef } from "react";

import PlayerContainer from "../../components/PlayerContainer/PlayerContainer";
import AboutContainer from "../../components/AboutContainer/AboutContainer";
import EffectLayer from "../../components/EffectLayer/EffectLayer";
import AudioPlayerController from "../../components/AudioPlayerController/AudioPlayerController";
import AudioSpectrum from "../../components/AudioSpectrum/AudioSpectrum";
import Header from "../../components/Header/Header";
import Select from "../../components/Select/Select";

import {
  getEffectPlayerData,
  setEffectPlayerData,
} from "../../services/players";
import { AudioContext } from "standardized-audio-context";
import { useLocation } from "react-router-dom";
import { useAuthParam } from "../../hooks/custom-hooks";
import NotFoundData from "../../components/NotFoundData/NotFoundData";
import { getDefaultEffectPlayerConfig } from "../../services/helper";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EffectPlayer() {
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment);
  const [effectType, id] = pathSegments;
  const { isAdmin } = useAuthParam();

  //Player Audio variables
  const [listAudio, setListAudio] = useState([]);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(-1);
  const [currentAudioURL, setCurrentAudioURL] = useState("");
  const [showError, setShowError] = useState(false);

  const [effectPlayerType, setEffectPlayerType] = useState(effectType);
  const [effectPlayerName, setEffectPlayerName] = useState(null);
  const [effectPlayerConfig, setEffectPlayerConfig] = useState(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1.0);
  const [duration, setDuration] = useState("00:00");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isEQGraphOpen, setEQGraphOpen] = useState(false);
  const [isAboutVisible, setIsAboutVisible] = useState(false);
  const [loadEffects, setLoadEffects] = useState(false);
  const [playerReady, setPlayerReady] = useState(false);
  const [turnOnSwitch, setTurnOnSwitch] = useState(true);

  const audioPlayerRef = useRef(null);
  const audioContextRef = useRef(null);
  const mediaElementSourceRef = useRef(null);

  const canvasWEQ = useRef(null);

  const toggleAbout = () => setIsAboutVisible(!isAboutVisible);

  //Load Data on first page load
  const fetchData = async (id) => {
    setEffectPlayerName(effectType.toUpperCase());

    try {
      const { data } = await getEffectPlayerData(effectType, id);

      if (data.audio_files && data.audio_files.length > 0) {
        setListAudio(data.audio_files);
      } else {
        setShowError(true);
      }

      if (!data.type_config || Object.keys(data.type_config).length === 0) {
        setEffectPlayerConfig(getDefaultEffectPlayerConfig(effectType));
      } else {
        setEffectPlayerConfig(data.type_config);
      }
    } catch (e) {
      setShowError(true);
    }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  useEffect(() => {
    async function loadFirstAudio() {
      if (listAudio.length > 0) {
        await loadNewTrack(0);
        setIsPlaying(false);
      }
    }

    loadFirstAudio();
  }, [listAudio]);

  const togglePlayback = () => {
    if (!audioPlayerRef.current || currentAudioIndex < 0) {
      if (currentAudioIndex === -1) {
        loadNewTrack(0);
      }
      return;
    }

    audioContextRef.current
      .resume()
      .then(() => {
        if (audioPlayerRef.current.paused) {
          audioPlayerRef.current.play();
          setIsPlaying(true);
          console.log("play", audioPlayerRef.current);
        } else {
          audioPlayerRef.current.pause();
          setIsPlaying(false);
        }
      })
      .catch((error) => {
        console.error("Error resuming the audio context:", error);
      });
  };

  const handleVolumeChange = (value) => {
    setVolume(Number(value));
    audioPlayerRef.current.volume = Number(value);
  };
  const toggleEQ = () => {
    setEQGraphOpen(!isEQGraphOpen);
  };

  //Load new Audio file
  const loadNewTrack = async (index) => {
    console.log("Loaded Player with Track-#" + index);

    setCurrentAudioIndex(index);
    // setCurrentAudioURL(`/audios/${listAudio[index].name}`);
    setCurrentAudioURL(listAudio[index].filename);

    setCurrentTime(0);
    setIsPlaying(false);

    // const newSrc = `/audios/${listAudio[index].name}`;
    // const audioElem = audioPlayerRef.current;
    // audioElem.src = newSrc;
    // audioElem.load();

    const audioElem = audioPlayerRef.current;
    audioElem.src = `/audios/${listAudio[index].filename}`;
    audioElem.load();
    setIsPlaying(true);

    if (!audioContextRef.current) {
      audioContextRef.current = new AudioContext();
    }
    const audioContext = audioContextRef.current;

    if (!mediaElementSourceRef.current && audioPlayerRef.current) {
      mediaElementSourceRef.current = audioContext.createMediaElementSource(
        audioPlayerRef.current
      );
      mediaElementSourceRef.current.connect(audioContext.destination);
    }
  };

  // Listen for AudioPlayerRef Events
  useEffect(() => {
    const audioElem = audioPlayerRef.current;
    audioElem.volume = volume;

    const handleTimeUpdate = () => {
      setCurrentTime(audioElem.currentTime);
    };

    const handleLoadedMetadata = () => {
      setDuration(audioElem.duration);
    };

    const handlePlaybackFinished = () => {
      console.log("Playback finished");
      setIsPlaying(false);
    };

    if (audioElem) {
      audioElem.addEventListener("timeupdate", handleTimeUpdate);
      audioElem.addEventListener("loadedmetadata", handleLoadedMetadata);
      audioElem.addEventListener("ended", handlePlaybackFinished);

      // Cleanup
      return () => {
        audioElem.removeEventListener("timeupdate", handleTimeUpdate);
        audioElem.removeEventListener("loadedmetadata", handleLoadedMetadata);
        audioElem.removeEventListener("ended", handlePlaybackFinished);
      };
    }
  }, [volume]);
  const handleAudioLoad = () => {
    console.log("Audio loaded!");
    setLoadEffects(true);
    // setPlayerReady(true)
  };

  const handleSeekClick = (value) => {
    console.log("Seek TO", value);
    audioPlayerRef.current.currentTime = value;
    if (isPlaying) {
      audioPlayerRef.current.play();
    }
  };
  const handleCanPlay = () => {
    setPlayerReady(true);
    //togglePlayback();
    console.log(audioContextRef.current);
    // if(isEQGraphOpen){
    // let spectrum = new SpectrumAnalyser(
    //   canvasWEQ.current,
    //   audioContextRef.current,
    //   {
    //     fftSize: 8192,
    //   }
    // );
    // spectrum.connect(mediaElementSourceRef.current);
    // spectrum.start();
    // }
  };

  const save = async () => {
    let newResponse = {
      audio_files: listAudio.map((item) => item._id),
      type_config: effectPlayerConfig,
    };

    await setEffectPlayerData(effectType, id, newResponse);
  };

  return (
    <>
      <audio
        ref={audioPlayerRef}
        controls={false}
        loop={true}
        onLoadedData={handleAudioLoad}
        onCanPlay={handleCanPlay}
        className="hidden w-full"
      >
        <source src="" type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
      <PlayerContainer isFullScreen={isFullScreen}>
        <Header
          label={effectPlayerName}
          turnOnSwitch={turnOnSwitch}
          onSwitch={setTurnOnSwitch}
          toggleSwitchValue={isEQGraphOpen}
          onToggleSwitch={toggleEQ}
          isAdmin={isAdmin}
        />
        {showError ? (
          <NotFoundData />
        ) : (
          <div
            className={`flex flex-col max-h-[calc(100vh-156px)] w-full h-full p-2 ${
              !isEQGraphOpen ? "overflow-auto" : "overflow-hidden"
            }`}
          >
            <div className="flex m-auto">
              <div
                className={`overflow-y-auto h-98 ${
                  !isEQGraphOpen ? "block" : "hidden"
                }`}
              >
                <EffectLayer
                  effectType={effectPlayerType}
                  effectConfig={effectPlayerConfig}
                  audioRef={audioPlayerRef}
                  mediaElementSourceRef={mediaElementSourceRef}
                  audioContextRef={audioContextRef}
                  isPlaying={isPlaying}
                  reloadEffect={loadEffects}
                  turnOnSwitch={turnOnSwitch}
                  updateConfig={setEffectPlayerConfig}
                />
              </div>
            </div>
            <div className="flex-1">
              {/*  <div className="mt-8 rounded-sm overflow-hidden w-full"> */}
              <div
                className={classNames(
                  isEQGraphOpen ? "block" : "hidden",
                  "overflow-hidden w-full rounded-sm h-full flex flex-col gap-4"
                )}
              >
                <Select
                  value={1}
                  options={[{ label: "Spectrum Analyzer", value: 1 }]}
                />
                <AudioSpectrum
                  audioContext={audioContextRef.current}
                  audioSource={mediaElementSourceRef.current}
                  isPlaying={isPlaying}
                />
                {/* <canvas
                  ref={canvasWEQ}
                  className="w-full h-full mt-6"
                  width={2000}
                  height={1000}
                ></canvas> */}
              </div>
              {/* </div> */}
            </div>
          </div>
        )}
        <AudioPlayerController
          isAboutVisible={isAboutVisible}
          audioIsPlaying={isPlaying}
          currentTime={currentTime}
          showSave={isAdmin}
          saveOnly={true}
          onSave={save}
          volume={volume}
          duration={duration}
          onPlayClick={togglePlayback}
          onVolumeChange={handleVolumeChange}
          onSeekTo={handleSeekClick}
          onAboutClick={toggleAbout}
          listAudio={listAudio}
          currentAudioIndex={currentAudioIndex}
          handleAudioItem={loadNewTrack}
        />
      </PlayerContainer>
      <AboutContainer isVisible={isAboutVisible} onClose={toggleAbout} />
    </>
  );
}
