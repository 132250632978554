export const getDefaultEffectPlayerConfig = (type) => {
  let defaultConfig = {};

  switch (type) {
    case "compressor":
      defaultConfig = {
        Threshold: {
          min: -100,
          max: 0,
          step: 0.1,
          value: -50,
        },
        Attack: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 0.5,
        },
        Knee: {
          min: 0,
          max: 40,
          step: 0.1,
          value: 20,
        },
        Ratio: {
          min: 1,
          max: 20,
          step: 1,
          value: 10,
        },
        Release: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 0.5,
        },
      };
      break;
    case "multibandcompressor":
      defaultConfig = {
        lowFrequency: {
          min: 0,
          max: 20000,
          step: 1,
          value: 250,
        },
        highFrequency: {
          min: 0,
          max: 20000,
          step: 1,
          value: 4000,
        },
        lowThreshold: {
          min: -100,
          max: 0,
          step: 1,
          value: -50,
        },
        lowRatio: {
          min: 1,
          max: 20,
          step: 1,
          value: 12,
        },
        lowAttack: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 0.3,
        },
        lowRelease: {
          min: 0,
          max: 1,
          step: 0.05,
          value: 0.25,
        },
        midThreshold: {
          min: -100,
          max: 0,
          step: 1,
          value: -24,
        },
        midRatio: {
          min: 1,
          max: 20,
          step: 1,
          value: 12,
        },
        midAttack: {
          min: 0,
          max: 1,
          step: 0.01,
          value: 0.03,
        },
        midRelease: {
          min: 0,
          max: 1,
          step: 0.05,
          value: 0.25,
        },
        highThreshold: {
          min: -100,
          max: 0,
          step: 1,
          value: -30,
        },
        highRatio: {
          min: 1,
          max: 20,
          step: 1,
          value: 12,
        },
        highAttack: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 0.3,
        },
        highRelease: {
          min: 0,
          max: 1,
          step: 0.05,
          value: 0.25,
        },
      };
      break;
    case "gate":
      defaultConfig = {
        Smoothing: {
          max: 1,
          min: 0.1,
          step: 0.1,
          value: 0.1,
        },
        Threshold: {
          max: 0,
          min: -300,
          step: 1,
          value: -30,
        },
      };
      break;
    case "limiter":
      defaultConfig = {
        Threshold: {
          min: -100,
          max: 0,
          step: 1,
          value: -12,
        },
      };
      break;
    case "bitcrusher":
      defaultConfig = {
        bits: {
          min: 1,
          max: 8,
          step: 1,
          value: 4,
        },
        wet: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 1,
        },
      };
      break;
    case "reverb":
      defaultConfig = {
        decay: {
          min: 0.1,
          max: 10,
          step: 0.1,
          value: 1.5,
        },
        preDelay: {
          min: 0,
          max: 1,
          step: 0.01,
          value: 0.01,
        },
        Wet: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 1,
        },
      };
      break;
    case "distortion":
      defaultConfig = {
        Distortion: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 0.4,
        },
      };
      break;
    case "midsidecompressor":
      defaultConfig = {
        midThreshold: {
          min: -100,
          max: 0,
          step: 1,
          value: -50,
        },
        midRatio: {
          min: 1,
          max: 20,
          step: 1,
          value: 12,
        },
        midAttack: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 0.3,
        },
        midRelease: {
          min: 0,
          max: 1,
          step: 0.05,
          value: 0.25,
        },
        midKnee: {
          min: 0,
          max: 40,
          step: 1,
          value: 40,
        },
        sideThreshold: {
          min: -100,
          max: 0,
          step: 1,
          value: -50,
        },
        sideRatio: {
          min: 1,
          max: 20,
          step: 1,
          value: 12,
        },
        sideAttack: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 0.3,
        },
        sideRelease: {
          min: 0,
          max: 1,
          step: 0.05,
          value: 0.25,
        },
        sideKnee: {
          min: 0,
          max: 40,
          step: 1,
          value: 40,
        },
      };
      break;
    case "delay":
      defaultConfig = {
        Feedback: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 0.4,
        },
        Time: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 0.7,
        },
        Wet: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 0.5,
        },
      };
      break;
    case "quadrafuzz":
      defaultConfig = {
        lowGain: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 0.4,
        },
        midLowGain: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 0.7,
        },
        midHighGain: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 0.5,
        },
        highGain: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 0.5,
        },
      };
      break;
    case "flanger":
      defaultConfig = {
        Frequency: {
          min: 0.1,
          max: 10,
          step: 0.1,
          value: 1.5,
        },
        Time: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 0.7,
        },
        Depth: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 0.5,
        },
        Feedback: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 0.5,
        },
        Wet: {
          min: 0,
          max: 1,
          step: 0.1,
          value: 0.5,
        },
      };
      break;
    case "phaser":
      defaultConfig = {
        Frequency: {
          min: 0.01,
          max: 100,
          step: 0.01,
          value: 0.5,
        },
        Octaves: {
          min: 0,
          max: 10,
          step: 0.1,
          value: 3,
        },
        Stages: {
          min: 1,
          max: 20,
          step: 1,
          value: 10,
        },
        Q: {
          min: 0.1,
          max: 10,
          step: 0.1,
          value: 2,
        },
        BaseFrequency: {
          min: 20,
          max: 20000,
          step: 1,
          value: 350,
        },
      };
      break;
    case "pitchShift":
      defaultConfig = {
        Pitch: {
          min: 1,
          max: 12,
          step: 0.1,
          value: 0.1,
        },
        DelayTime: {
          min: 0,
          max: 1,
          step: 0.01,
          value: 0.1,
        },
        Feedback: {
          min: 0,
          max: 1,
          step: 0.01,
          value: 0,
        },
        WindowSize: {
          min: 0.03,
          max: 0.1,
          step: 0.01,
          value: 0.5,
        },
      };
      break;
    case "stereowidener":
      defaultConfig = {
        Width: {
          min: 0,
          max: 1,
          step: 0.01,
          value: 0.5,
        },
        Wet: {
          min: 0,
          max: 1,
          step: 0.01,
          value: 1,
        },
      };
      break;
  }

  return defaultConfig;
};
