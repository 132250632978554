import React from "react";
import { IconSettings, IconWaveSine } from "@tabler/icons-react";
import { Tooltip } from "flowbite-react";

const ToggleSwitch = ({ isChecked, handleToggle }) => {
  return (
    <label className="slider bg-grey-500 rounded-full relative inline-block w-28 h-9">
      <input
        type="checkbox"
        className="opacity-0 w-0 h-0"
        checked={isChecked}
        onChange={() => handleToggle()}
      />
      <span
        className={`slider-icon shadow-inner absolute flex justify-center items-center h-9 w-14 left-0 bottom-0 bg-grey-400 rounded-full transition-transform ${
          isChecked ? "transform translate-x-14" : ""
        }`}
      ></span>
      <span className="absolute flex justify-center items-center h-9 w-14 left-0 bottom-0 text-lg">
        <Tooltip content="Configure" style="light" className="mt-2">
          <IconSettings />
        </Tooltip>
      </span>
      <span
        className={`absolute flex justify-center items-center h-9 w-14 right-0 bottom-0 text-lg transition-opacity ${
          isChecked ? "opacity-100" : "opacity-100"
        }`}
      >
        <Tooltip content="Analyze" style="light" className="mt-2">
          <IconWaveSine />
        </Tooltip>
      </span>
    </label>
  );
};

export default ToggleSwitch;
