import * as Tone from "tone";
import { sampleInstruments } from "./constants";

export const updatePitch = (sound, pitch) => {
  rackPlayers.player(sound.name).playbackRate = pitch;
};

export const updateTempo = (tempo) => {
  Tone.Transport.bpm.value = tempo / 2;
};

export const muteVolume = (sound, value) => {
  rackPlayers.player(sound.name).mute = value;
};

export const updateVolume = (sound, volume) => {
  rackPlayers.player(sound.name).volume.value = volume;
};

export const startPlaying = async () => {
  try {
    await Tone.start();
    Tone.Transport.start("+0.1");
  } catch (e) {
    console.log("error", e);
  }
};

export const stopPlaying = async () => {
  await Tone.Transport.stop();
};

export const getTransportPosition = () => {
  /* ["0", "0", "0"] <--
   * ["0", "0", "3"] <--
   * ["0", "1", "0"] <--  */
  const pos = Tone.Transport.position.split(".")[0].split(":");
  return Number(pos[1]) * 4 + Number(pos[2]);
};

export const rackPlayers = new Tone.Players().toDestination();

export const playSound = (name) => rackPlayers.player(name).start();

export const sequence = (sound, callback = () => {}) => {
  return new Tone.Sequence(
    (time, note) => {
      if (note.notes.includes(1)) {
        rackPlayers.player(sound.name).start(time);
      }
      callback();
    },
    new Array(16).fill({ notes: [0] }),
    "16n"
  );
};

export const getSamplesList = () =>
  sampleInstruments.map((sample) => ({
    ...sample,
    url: `${process.env.PUBLIC_URL}${sample.url}`,
  }));
