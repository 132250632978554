import React from "react";
import { Switch as HeadlessSwitch } from "@headlessui/react";

const Switch = ({ className, checked = false, disabled = false, onChange }) => (
  <HeadlessSwitch
    disabled={disabled}
    checked={checked}
    onChange={onChange}
    className={`${
      checked ? "bg-green-300" : "bg-red-300"
    } relative inline-flex h-6 w-10 cursor-pointer items-center rounded-full transition-colors duration-200 ease-in-out ${className}`}
  >
    <span
      className={`${
        checked ? "translate-x-[18px]" : "translate-x-[2px]"
      } inline-block h-5 w-5 transform rounded-full bg-grey-400 transition duration-200 ease-in-out`}
    />
  </HeadlessSwitch>
);

export default Switch;