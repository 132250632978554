import React, { useState } from "react";
import { Direction, Range, getTrackBackground } from "react-range";

export default function VerticalSlider({
  className,
  min,
  max,
  value,
  step,
  onChange,
}) {
  return (
    <div className={`flex h-[150px] items-center`}>
      <Range
        step={step}
        min={min}
        max={max}
        direction={Direction.Up}
        values={[value]}
        onChange={(values) => onChange(values[0])}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            className="w-[36px] flex h-full"
            style={{
              ...props.style,
            }}
          >
            <div
              ref={props.ref}
              className="w-[10px] h-full rounded-[4px] self-center"
              style={{
                boxShadow: "rgb(20, 20, 20) 0px 2px 6px",
                background: getTrackBackground({
                  values: [value],
                  colors: ["#fff", "#c0c0c0"],
                  min: min,
                  max: max,
                  direction: Direction.Up,
                }),
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            className="h-[30px] w-[30px] rounded-full bg-white flex justify-center items-center"
            {...props}
            style={{
              ...props.style,
              boxShadow: "0px 2px 6px #AAA",
            }}
          ></div>
        )}
      />
    </div>
  );
}
