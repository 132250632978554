import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { IconSelector, IconTrash, IconPencil } from "@tabler/icons-react";

export default function Dropdown({
  openDirection = "up", // 'up' or 'down'
  placeholder = "Select...",
  options,
  currentIndex,
  handleOptionClickEvent,
  mobileIcon,
  mobileOnly,
  editable = false,
  onEdit,
  deletable = false,
  isDarkTheme = false,
  onDelete,
}) {
  const callClickEvent = (index) => {
    handleOptionClickEvent(index);
  };

  return (
    <Menu
      as="div"
      className={`relative inline-block text-left min-w-[48px] min-h-[48px] ${
        mobileOnly ? "" : "md:min-w-[200px]"
      }`}
    >
      {({ open }) => (
        <>
          <div className={`${open ? "z-30" : ""} absolute bottom-0 right-0`}>
            <Menu.Button
              className={`${
                isDarkTheme ? "bg-grey-300" : "bg-grey-600"
              } flex min-w-[48px] ${
                mobileOnly ? "" : "md:min-w-[200px]"
              } justify-start gap-x-1.5 rounded-md p-3 text-sm font-semibold text-gray-900 shadow-sm hover:bg-grey-500`}
            >
              <>
                {!mobileOnly && (
                  <div
                    className={`w-fit ${
                      mobileIcon ? "hidden" : "block"
                    } md:block`}
                  >
                    {currentIndex === -1
                      ? placeholder
                      : options[currentIndex]?.name?.slice(0, 20) + "..."}
                  </div>
                )}
                <div className={`${mobileOnly ? "" : "md:hidden"}`}>
                  {mobileIcon}
                </div>
                <IconSelector
                  className={`absolute right-2 -mr-1 ${
                    mobileOnly ? "" : "md:block"
                  } hidden`}
                  size={20}
                />
                <IconSelector
                  className={`absolute right-1 top-1 ${
                    mobileOnly ? "" : "md:hidden"
                  }`}
                  size={10}
                />
              </>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={`absolute ${
                openDirection === "up" ? "bottom-12" : "top-12"
              } right-0 z-30 my-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-0 md:ring-1 ring-black ring-opacity-5 focus:outline-none`}
            >
              <div className="py-1">
                {options &&
                  options.map((item, index) => {
                    return (
                      <Menu.Item key={index}>
                        <a
                          href="#"
                          className={`${
                            currentIndex === index
                              ? "rounded-md  bg-blue-300 text-grey-500"
                              : "text-gray-700"
                          } mx-1 px-4 py-2 text-sm flex justify-between items-center`}
                          onClick={() => callClickEvent(index)}
                        >
                          {item.name}
                          <div className="flex gap-2">
                            {editable &&
                              <IconPencil size={16} onClick={() => onEdit()} />

                            }
                            {deletable &&
                              <IconTrash size={16} onClick={() => onDelete(index)} />
                            }
                          </div>
                        </a>
                      </Menu.Item>
                    );
                  })}
              </div>
            </Menu.Items>
          </Transition>
          {open && (
            <div className="fixed inset-0 z-20 bg-grey-100 opacity-70"></div>
          )}
        </>
      )}
    </Menu>
  );
}
