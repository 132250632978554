import { useRef, useEffect } from "react";
import SpectrumAnalyser from "../../services/spectrumAnalyser";
export default function AudioSpectrum({ audioContext, audioSource, isPlaying }) {
  const canvasRef = useRef(null);
  const spectrumAnalyserRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current && audioContext && audioSource) {
      // Initialize the SpectrumAnalyser with the canvas and audio context
      spectrumAnalyserRef.current = new SpectrumAnalyser(
        canvasRef.current,
        audioContext,
        {
          fftSize: 8192, // Example FFT size, adjust as needed
          backgroundColor: "rgba(255, 255, 255, 0)",
          spectrumColor: "black",
        }
      );

      // Connect the audio source to the analyser
      spectrumAnalyserRef.current.connect(audioSource);

      return () => {
        if (spectrumAnalyserRef.current) {
          spectrumAnalyserRef.current.stop();
          spectrumAnalyserRef.current.disconnect(audioSource);
        }
      };
    }
  }, [audioContext, audioSource]);

  useEffect(() => {
    // Start or stop the visualization based on isPlaying
    if (isPlaying) {
      spectrumAnalyserRef.current?.start();
    } else {
      spectrumAnalyserRef.current?.stop();
    }
  }, [isPlaying]);

  return (
    <canvas
      ref={canvasRef}
      width={2000}
      height={600}
      style={{ width: "100%", height: "100%" }}
    ></canvas>
  );
}
