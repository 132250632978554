import React, { useState, useEffect } from "react";
import { MdPlayArrow, MdAdd } from "react-icons/md";

import { rackPlayers, playSound } from "../../services/stepSequencer";
import Button from "../Button/Button";

const SingleSample = ({ displayName, name, url, onAdd }) => {
  const [isPlaying, setPlaying] = useState(false);
  const handlePlay = () => {
    if (isPlaying === false) {
      setPlaying(true);
    }
  };

  useEffect(() => {
    if (!rackPlayers.has(name)) {
      rackPlayers.add(name, url, () => {});
    }
  }, []);

  useEffect(() => {
    if (isPlaying === true) {
      playSound(name);
      setPlaying(false);
    }
  }, [isPlaying]);

  return (
    <div className="flex justify-between items-center p-1 hover:bg-gray-300">
      <div className="flex gap-2">
        <Button
          className="p-1"
          type="button"
          onClick={handlePlay}
          disabled={isPlaying === true}
        >
          <MdPlayArrow size={20} />
        </Button>
        <div>{displayName}</div>
      </div>
      <Button className="" type="button" onClick={onAdd}>
        <MdAdd size={24} />
      </Button>
    </div>
  );
};

export default SingleSample;
