import React, { useEffect, useState } from "react";
import PlayerContainer from "../../components/PlayerContainer/PlayerContainer";
import AboutContainer from "../../components/AboutContainer/AboutContainer";
import Header from "../../components/Header/Header";
import AudioPlayerController from "../../components/AudioPlayerController/AudioPlayerController";

import { getSamplesList } from "../../services/stepSequencer";
import { presetSampleData } from "../../services/constants";
import {
  updateTempo,
  startPlaying,
  stopPlaying,
} from "../../services/stepSequencer";

const samplesList = getSamplesList();

export default function MidiPlayer() {
  const [addSampleOpen, setAddSampleOpen] = useState(false);
  const [currentTempo, setCurrentTempo] = useState(120);
  const [masterVolume, setMasterVolume] = useState(1);
  const [samples, setSamples] = useState(samplesList.slice(0, 4));
  const [preset, setPreset] = useState(-1);
  const [isPlaying, setPlaying] = useState(false);
  const [isAboutVisible, setIsAboutVisible] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDrawerOpened, setDrawerOpened] = useState(true);

  useEffect(() => {
    updateTempo(currentTempo);
  }, []);

  useEffect(() => {
    if (preset > -1) {
      const newSamples = [];
      const presetData = presetSampleData[preset].data;
      presetData.map((instrument) => {
        newSamples.push({
          ...samplesList[instrument.instrumentId],
          initialNotes: instrument.initialNotes,
          initialVolume: instrument.initialVolume,
        });
      });
      stopPlaying();
      setPlaying(false)
      setSamples(newSamples);
    }
  }, [preset]);

  const play = () => {
    if (isPlaying) {
      stopPlaying();
      setPlaying(false);
    } else {
      startPlaying();
      setPlaying(true);
    }
  };

  const refresh = () => {
    const emptySamplesList = [];
    setSamples(emptySamplesList);
  };

  const activateSample = (sample) => {
    const newSamplesList = [
      ...samples.filter((item) => item.name !== sample),
      ...samplesList.filter((item) => item.name === sample),
    ];
    setSamples(newSamplesList);
  };

  const removeSample = (sample) => {
    const newSamplesList = [...samples.filter((item) => item.name !== sample)];
    setSamples(newSamplesList);
  };

  const onTempoChange = (tempo) => {
    setCurrentTempo(tempo);
    updateTempo(tempo);
  };

  const onHandleVolume = (value) => {
    setMasterVolume(value)
  }
  
  return (
    <PlayerContainer>
      <Header label="Midi Player" />
      <div className="flex h-full">
        
      </div>
      <AudioPlayerController
        isAboutVisible={isAboutVisible}
        audioIsPlaying={isPlaying}
        onPlayClick={play}
        onAboutClick={() => setIsAboutVisible(!isAboutVisible)}
        volume={masterVolume}
        showVolume={false}
        onVolumeChange={onHandleVolume}
        onTempoChange={onTempoChange}
        handlePresetItem={setPreset}
        currentTempo={currentTempo}
        currentPresetIndex={preset}
        listPreset={presetSampleData.map((item, index) => ({
          name: item.preset_name,
          value: index,
        }))}
      />
      <AboutContainer
        isVisible={isAboutVisible}
        onClose={() => setIsAboutVisible(false)}
      >
        <h2 className="text-2xl font-bold mb-4">Step Sequencer</h2>
        <p className="mb-4">
          We'd love to hear any suggestions you have about how to improve this
          player. To make a suggestion, click here.
        </p>
        <p className="mb-4">Keep humming!</p>
      </AboutContainer>
    </PlayerContainer>
  );
}
