import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import AudioPlayer from "react-h5-audio-player";

import "react-h5-audio-player/lib/styles.css";

const MediaPlayer = ({ source }) => {
  const { name } = useParams();

  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);

  const togglePlay = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleSeek = (event) => {
    const seekTime = parseFloat(event.target.value);
    audioRef.current.currentTime = seekTime;
    setCurrentTime(seekTime);
  };

  return (
    <AudioPlayer
      autoPlay={false}
      src={`/audios/${name}`}
      onPlay={(e) => console.log("onPlay")}
      // other props here
    />
  );
};

export default MediaPlayer;
