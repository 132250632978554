import React from "react";

class Dial extends React.Component {
  constructor(props) {
    super(props);
    this.fullAngle = props.degrees;
    this.startAngle = (360 - props.degrees) / 2;
    this.endAngle = this.startAngle + props.degrees;
    this.currentDeg = Math.floor(
      this.convertRange(
        props.min,
        props.max,
        this.startAngle,
        this.endAngle,
        props.value
      )
    );
    this.state = { deg: this.currentDeg, value: props.value };
  }

  findClosestStepValue(input, min, max, step) {
    const distance = input - min;
    let steps = Math.round(distance / step);
    steps = Math.max(0, Math.min(steps, Math.floor((max - min) / step)));
    const newValue = min + steps * step;

    return newValue.toFixed(1);
  }

  startDrag = (e) => {
    e.preventDefault();
    const knob = e.target.getBoundingClientRect();
    const pts = {
      x: knob.left + knob.width / 2,
      y: knob.top + knob.height / 2,
    };
    const moveHandler = (e) => {
      if (e.type === "mousemove") {
        this.currentDeg = this.getDeg(e.clientX, e.clientY, pts);
      } else if (e.type === "touchmove") {
        this.currentDeg = this.getDeg(
          e.touches[0].clientX,
          e.touches[0].clientY,
          pts
        );
      }
      if (this.currentDeg === this.startAngle) this.currentDeg--;
      let newValue = this.convertRange(
        this.startAngle,
        this.endAngle,
        this.props.min,
        this.props.max,
        this.currentDeg
      );
      this.setState({ deg: this.currentDeg, value: newValue });
      this.props.onChange(newValue);
    };
    document.addEventListener("mousemove", moveHandler);
    document.addEventListener("touchmove", moveHandler, { passive: false });
    document.addEventListener("mouseup", (e) => {
      document.removeEventListener("mousemove", moveHandler);
    });
    document.addEventListener("touchend", (e) => {
      document.removeEventListener("touchmove", moveHandler);
    });
  };

  getDeg = (cX, cY, pts) => {
    const x = cX - pts.x;
    const y = cY - pts.y;
    let deg = (Math.atan(y / x) * 180) / Math.PI;
    if ((x < 0 && y >= 0) || (x < 0 && y < 0)) {
      deg += 90;
    } else {
      deg += 270;
    }
    let finalDeg = Math.min(Math.max(this.startAngle, deg), this.endAngle);

    return finalDeg;
  };

  convertRange = (oldMin, oldMax, newMin, newMax, oldValue) => {
    return this.findClosestStepValue(
      ((oldValue - oldMin) * (newMax - newMin)) / (oldMax - oldMin) + newMin,
      newMin,
      newMax,
      0.1
    );
  };

  dcpy = (o) => {
    return JSON.parse(JSON.stringify(o));
  };

  render() {
    let kStyle = {
      width: this.props.size,
      height: this.props.size,
    };
    let iStyle = this.dcpy(kStyle);
    let oStyle = this.dcpy(kStyle);

    if (this.props.color) {
      oStyle.backgroundImage = `radial-gradient(circle, white, rgba(214, 214, 214, ${
        this.currentDeg / 360
      }))`;
    }
    iStyle.transform = "rotate(" + this.state.deg + "deg)";

    return (
      <div className="relative px-2">
        <div className="flex flex-col  relative" style={kStyle}>
          <div
            className="rounded-full shadow-[-1px_0px_3px_4px_#D6D6D6]"
            style={oStyle}
            onMouseDown={this.startDrag}
            onTouchStart={this.startDrag}
          >
            <div className="absolute text-lg text-grey-1 left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2">
              {this.state.value}
            </div>
            <div className="rounded-full" style={iStyle}>
              <div className="absolute w-[8%] h-[8%] bottom-[4%] left-[50%] rounded-full bg-grey-100 transform -translate-x-1/2" />
            </div>
          </div>
        </div>
        {/* <div className="absolute text-xs left-0 bottom-5">{this.props.min}</div>*/}
        <div
          className={`absolute text-xs bottom-5 ${
            this.props.min.toString().includes(".") ? "-left-4" : "left-0"
          }`}
        >{this.props.min}</div>
        <div className="absolute text-xs right-0 bottom-5">{this.props.max}</div>
        <div className="text-center text-sm text-grey-1 font-semibold mt-2">{this.props.label}</div>
      </div>
    );
  }
}
Dial.defaultProps = {
  label: "label",
  size: 150,
  min: 10,
  max: 30,
  degrees: 270,
  value: 0,
  onChange: () => {},
};

export default Dial;
