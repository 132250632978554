class SpectrumAnalyser {
    constructor(element, audioCtx, { 
        fftSize = 8192, 
        backgroundColor = 'rgba(255, 255, 255, 0.6)', 
        spectrumColor = 'gray' 
    } = {}) {
        this.element = element;
        this.audioCtx = audioCtx;
        this.analyser = audioCtx.createAnalyser();
        this.analyser.fftSize = fftSize;
        this.backgroundColor = backgroundColor;
        this.spectrumColor = spectrumColor;
        this.running = false;
        this.data = null;
    }

    connect(audioSource) {
        audioSource.connect(this.analyser);
    }

    disconnect(audioSource) {
        audioSource.disconnect(this.analyser);
    }

    start() {
        this.running = true;
        this.data = new Uint8Array(this.analyser.frequencyBinCount);
        this.frame();
    }

    stop() {
        this.running = false;
        this.data = null;
    }

    frame = () => {
        if (!this.running) return;

        this.analyser.getByteFrequencyData(this.data);
        this.renderFrame();

        requestAnimationFrame(this.frame);
    }

    renderFrame() {
        // let width = this.element.offsetWidth * window.devicePixelRatio;
        // let height = this.element.offsetHeight * window.devicePixelRatio;
        // console.log(this.element);
        let width = 2000 ;
        let height = 1000 ;
        if(this.element.width !== null){
            width = this.element.width ;
            height = this.element.height ;
        }
        
        let logMinFreq = Math.log10(10);
        let logMaxFreq = Math.log10(this.audioCtx.sampleRate / 2);
        this.element.width = width;
        this.element.height = height;

        let ctx = this.element.getContext("2d");
        ctx.fillStyle = this.backgroundColor;
        ctx.fillRect(0, 0, width, height);

        ctx.fillStyle = this.spectrumColor;

        // Draw Grid here
        this.drawGrid(width, height, ctx);
        // End Grid draw here
        

        // Store points for later use in stroke drawing
        let points = [];

        // Begin the path for the spectrum fill
        ctx.beginPath();
        ctx.moveTo(0, height); // Start from the bottom left

        for (let i = 0; i < this.data.length; i++) {
            let binFreq = (i * this.audioCtx.sampleRate) / this.analyser.fftSize;
            let x = ((Math.log10(binFreq) - logMinFreq) / (logMaxFreq - logMinFreq)) * width;
            let pointHeight = (this.data[i] / 256) * height;
            ctx.lineTo(x, height - pointHeight); // Draw line to each point
            
            // Save the points for drawing the stroke later
            points.push({x, y: height - pointHeight});
        }

        ctx.lineTo(width, height); // Close the path by connecting back to the bottom right
        ctx.closePath(); // Ensures the fill operation affects the whole area

        // Define and apply the gradient for the fill
        let gradient = ctx.createLinearGradient(0, 0, 0, height);
        gradient.addColorStop(0, 'rgba(31,31,51,0.7)'); // Start with purple
        gradient.addColorStop(1, 'rgba(31,31,51,0.7)'); // End with black
        ctx.fillStyle = gradient;
        ctx.fill(); // Fill the closed path with the gradient

        // Redraw the path for the stroke, varying line width
        ctx.beginPath();
        ctx.moveTo(0, height); // Start from the bottom left again

        points.forEach((point, index) => {
            ctx.lineTo(point.x, point.y); // Redraw line to each saved point
            
            // Dynamically adjust the lineWidth based on the x position
            ctx.lineWidth = 2 + (point.x / width) * 0.01; // Example: increases lineWidth from 1 to 10 towards the right
            ctx.strokeStyle = 'rgb(155,155,255)'; // Set the stroke color to red
            ctx.stroke(); // Apply the stroke with the current settings
            
            // Start a new path to reset the stroke settings for varying lineWidth
            if (index < points.length - 1) {
                ctx.beginPath();
                ctx.moveTo(point.x, point.y);
            }
        });

        ctx.lineTo(width, height); // Ensure the path is closed by connecting back to the bottom
        ctx.stroke(); // Apply the final stroke
    }

    drawGrid(cvwidth, cvheight, ctx){
        const segmentFrequencies = 5; // Number of frequency bands per segment
        const amplitudeLevels = 10; // Number of amplitude levels
        const segmentWidth = 200; // Width of each repeating segment
        const segments = cvwidth / segmentWidth; // Calculate how many segments fit into the canvas
    
        // Function to draw one segment of the grid
        function drawSegment(segmentStartX) {
            // Create decreasing widths from left to right within a segment
            let accumulatedSegmentWidth = 0;
            const widths = Array.from({length: segmentFrequencies}, (_, i) => Math.pow(segmentFrequencies - i, 2));
            const totalWidthSum = widths.reduce((acc, val) => acc + val, 0);
            const normalizedWidths = widths.map(width => width / totalWidthSum * segmentWidth);
    
            // Draw each frequency band with its corresponding intensity width within a segment
            normalizedWidths.forEach((width, index) => {
                ctx.beginPath();
                ctx.rect(segmentStartX + accumulatedSegmentWidth, 0, width, cvheight);
                ctx.strokeStyle = 'black'; // Set the stroke color to black
                ctx.stroke(); // Draw only the outline
                ctx.closePath();
                accumulatedSegmentWidth += width; // Update accumulated width for next band within the segment
            });
        }
    
        // Repeat the pattern across the canvas width
        for (let i = 0; i < segments; i++) {
            drawSegment(i * segmentWidth);
        }
    
        // Draw horizontal amplitude lines
        for (let i = 1; i <= amplitudeLevels; i++) {
            const y = cvheight / (amplitudeLevels + 1) * i;
            ctx.beginPath();
            ctx.moveTo(0, y);
            ctx.lineTo(cvwidth, y);
            ctx.strokeStyle = 'rgba(0,0,0,0.5)'; // Lighter black for amplitude lines
            ctx.stroke();
        }
    }
}

export default SpectrumAnalyser;