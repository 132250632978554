import React, { memo, useState } from "react";
import { IconVolume, IconVolumeOff } from "@tabler/icons-react";
import VolumeSliderModal from "./VolumeSliderModal";
import HorizontalSlider from "../Slider/HorizontalSlider";
import { useHover } from "../../hooks/custom-hooks";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import {
  muteVolume,
  playSound,
  updateVolume,
} from "../../services/stepSequencer";

export default function InstrumentName({ sample }) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentVolume, setCurrentVolume] = useState(0);
  const [isMuted, setMuted] = useState(false);
  const { isHovered, onMouseEnter, onMouseLeave } = useHover();

  const onVolumeChange = (e) => {
    setCurrentVolume(e);
    updateVolume(sample, e);
  };

  const onMute = () => {
    muteVolume(sample, !isMuted);
    setMuted(!isMuted);
  };

  return (
    <div
      onClick={() => playSound(sample.name)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`group cursor-pointer relative flex-1 flex items-center justify-between hover:bg-grey-100 ${
        isMuted
          ? "lg:hover:text-grey-300 lg:text-grey-300"
          : "lg:hover:text-white"
      }`}
    >
      <div className="lg:min-w-[130px] px-2 flex-1 flex items-center font-bold text-grey-100 group-hover:text-white">
        {sample.displayName}
      </div>
      <button
        className="lg:hidden group-hover:text-white px-2 py-1"
        onClick={() => setIsOpen(true)}
      >
        {isMuted ? <IconVolumeOff size={20} /> : <IconVolume size={20} />}
      </button>
      <button className="hidden lg:block px-2 py-1" onClick={() => onMute()}>
        {isMuted ? <IconVolumeOff size={20} /> : <IconVolume size={20} />}
      </button>
      <div className="hidden lg:flex items-center px-2">
        <HorizontalSlider
          isDisabled={isMuted}
          min={-30}
          max={3}
          isHovered={isHovered}
          className="w-[180px]"
          onChange={onVolumeChange}
          value={currentVolume}
        />
        <div
          className={`font-bold text-sm mt-0.5 ml-4 w-[65px] text-right `}
        >{`${currentVolume} dB`}</div>
      </div>
      <div className="lg:hidden">
        <VolumeSliderModal
          isMuted={isMuted}
          onMute={() => onMute()}
          value={currentVolume}
          onChange={onVolumeChange}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      </div>
    </div>
  );
}
