import React, { useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import { IconDeviceFloppy } from "@tabler/icons-react";

export default function SaveDropdownButton({
  onSave = () => {},
  onSaveAs = () => {},
  isDarkTheme = false,
  saveOnly = false,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const options = [
    { name: "Save", value: "save" },
    { name: "Save As", value: "save-as" },
  ];

  const optionsOnlySave = [{ name: "Save", value: "save" }];

  const handleItem = (index) => {
    setCurrentIndex(index);

    if (index === 0) {
      onSave();
    } else {
      onSaveAs();
    }
  };

  return (
    <Dropdown
      isDarkTheme={isDarkTheme}
      placeholder="Select..."
      options={saveOnly ? optionsOnlySave : options}
      currentIndex={currentIndex}
      handleOptionClickEvent={handleItem}
      mobileIcon={<IconDeviceFloppy />}
      mobileOnly
    />
  );
}
