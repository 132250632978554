import { Tooltip } from "flowbite-react";
import React, { useState } from "react";
import { Range, getTrackBackground } from "react-range";
import { useHover } from "../../hooks/custom-hooks";

export default function ProgressBar({
  className,
  min = 0,
  max = 100,
  value,
  step = 1,
  onChange,
  isPlaying,
  isDisabled,
}) {
  const { isHovered, onMouseEnter, onMouseLeave } = useHover();
  const getMinutes = (t) => {
    // setCurrentDuration(t);
    // setCurrentTime(audio.currentTime);
    const min = parseInt(t / 60);
    const sec = parseInt(t % 60);
    return `${"0" + min}:${sec < 10 ? "0" + sec : sec}`;
  };

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`flex w-full justify-center group`}
    >
      <Range
        disabled={isDisabled}
        step={step}
        min={min}
        max={max}
        values={[value]}
        onChange={(values) => onChange(values[0])}
        renderTrack={({ props, children }) => (
          <div
            className="h-[30px] flex w-full"
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
            }}
          >
            <div
              ref={props.ref}
              className="h-[10px] w-full rounded-full"
              style={{
                background: getTrackBackground({
                  values: [value],
                  colors: isDisabled
                    ? ["#ccc", "#ccc"]
                    : [
                        isHovered
                          ? "#212121"
                          : isPlaying
                          ? "#5e5e5e"
                          : "#d6d6d6",
                        "#F9F9F9",
                      ],
                  min: min,
                  max: max,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            className="h-5 w-5 relative rounded-full bg-translate flex justify-center items-center active:border-none focus:ring-0 focus:border-none active:outline-none focus:outline-none"
            {...props}
            style={{
              ...props.style,
            }}
          >
            <div className="absolute -top-[40px] z-50 rounded-md p-1 bg-black group-hover:block hidden text-white">
              {getMinutes(value) + "/" + getMinutes(max)}
            </div>
            <div className="absolute -top-[25px] left-1/2 hidden h-5 w-5 -translate-x-1/2 rotate-45 transform bg-grey-100 group-hover:block"></div>

            <div className="absolute w-5 h-5 group-hover:bg-grey-100 rounded-full"></div>
          </div>
        )}
      />
    </div>
  );
}
