import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

const Modal = ({
  className,
  children,
  initialFocus,
  fullSize = false,
  open = false,
  showCloseButton = true,
  onClose = () => {},
}) => {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={showCloseButton ? onClose : () => {}}
        initialFocus={initialFocus}
      >
        <div className="min-h-dvh text-center lg:min-h-screen lg:px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-70 bg-gradient-to-b bg-grey-100 backdrop-blur-md">
              {showCloseButton && (
                <button
                  className="absolute right-7.5 top-7.5 hidden w-10  cursor-pointer justify-center py-2 text-black-900 transition-all lg:flex lg:text-white"
                  onClick={() => onClose(true)}
                >
                  X
                </button>
              )}
            </Dialog.Overlay>
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-dvh align-middle lg:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={`inline-flex justify-center items-center ${
                fullSize ? "w-full " : "w-full lg:w-1/2 "
              } bg-translate my-0 min-h-dvh max-w-full transform p-6 text-left align-middle transition-all lg:my-8 lg:min-h-0 lg:rounded-2xl ${className}`}
            >
              {showCloseButton && (
                <button
                  className="absolute right-4 top-10 flex w-10 cursor-pointer justify-center py-2 text-black-900 transition-all lg:hidden lg:text-white"
                  onClick={() => onClose(true)}
                >
                  <div className="h-5 w-5">X</div>
                </button>
              )}
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
