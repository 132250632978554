import React, { useState, useEffect } from "react";

import Slider from "../Slider/Slider";
import RefreshButton from "./RefreshButton";
import DeleteButton from "./DeleteButton";

import {
  sequence,
  rackPlayers,
  getTransportPosition,
} from "../../services/stepSequencer";
import { updateVolume } from "../../services/stepSequencer";

// Single step
const Step = ({ initial, setStep, name, cursor, isMuted }) => {
  const [box, setBox] = useState(false);

  useEffect(() => {
    setBox(initial);
  }, [initial]);

  return (
    <div className="flex-1 w-full h-full  block relative">
      <label className="flex-1 w-full h-full  absolute top-0 left-0">
        <input
          id="name"
          className="opacity-0 absolute left-0 flex-1 w-full h-full "
          type="checkbox"
          checked={box}
          name={name}
          onChange={(e) => {
            setBox(e.target.checked);
            setStep(e.target.checked);
          }}
        />
        <div
          className={`${
            box ? (isMuted ? "bg-grey-300" : "bg-grey-100") : isMuted ? 'bg-gray-100': 'bg-gray-200'
          } absolute top-0 flex-1 w-full h-full  ${
            cursor && !isMuted
              ? "border-2 border-gray-900"
              : "border-gray-600"
          }  `}
        />
      </label>
    </div>
  );
};

/*
 * Set state of step in steps array
 * pos: Number. Position of the step in the Array(16)
 * value: Boolean. True if there is a hit on this note.
 * steps: Array. Current sequence: [false, true, ...]
 * setSteps: Setter function.
 */

const setSingleStep = (pos, value, steps, setSteps) => {
  setSteps([...steps.slice(0, pos), value, ...steps.slice(pos + 1)]);
};

const DrumRack = ({ showSetting, isAdmin, sound, remove }) => {
  const [currentVolume, setCurrentVolume] = useState(0);
  const [seq, setSeq] = useState();
  const [steps, setSteps] = useState(new Array(16).fill(false));
  const [position, setPosition] = useState(0);
  const [loaded, setLoaded] = useState(false);
  
  const isMuted = rackPlayers.has(sound.name)
    ? rackPlayers.player(sound.name).mute
    : false;

  const invokeCb = () => {
    setPosition(getTransportPosition());
  };

  // On mount, we add the sound to the core.
  useEffect(() => {
    if (!rackPlayers.has(sound.name)) {
      rackPlayers.add(sound.name, sound.url, () => {});
      setLoaded(true);
    } else {
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    setSteps(
      sound.initialNotes
        ? sound.initialNotes
            .split("")
            .map((str) => (str === "1" ? true : false))
        : new Array(16).fill(false)
    );

    if (sound.initialVolume) {
      setCurrentVolume(sound.initialVolume);
      updateVolume(sound, sound.initialVolume);
    }
  }, [sound]);

  // When mount, create a new empty sequence for the sample.
  useEffect(() => {
    const newSequence = sequence(sound, () => {
      invokeCb();
    }).start(0);

    setSeq(newSequence);

    return () => {
      // Destroy sequence on unmount
      newSequence.stop();
      newSequence.dispose();
    };
  }, []);

  // Set volume value to slider's value;
  useEffect(() => {
    updateVolume(sound, currentVolume);
  }, []);

  return (
    <>
      <div className="flex flex-1 gap-0.5 items-center">
        {!loaded && (
          <div className="">
            <h3> Loading </h3>
          </div>
        )}
        <div className="flex-1 h-full flex gap-0.5">
          {loaded &&
            steps.map((step, index) => {
              seq.events[index] = { notes: step === true ? [1] : [] };

              return (
                <Step
                  isMuted={isMuted}
                  name={index + 1}
                  key={index}
                  initial={step}
                  setStep={(value) => {
                    setSingleStep(index, value, steps, setSteps);
                    seq.events[index] = { notes: value === true ? [1] : [] };
                  }}
                  cursor={position === index}
                />
              );
            })}
        </div>
        {isAdmin && (
          <div className="hidden gap-0.5 md:flex items-center">
            <RefreshButton
              onClick={() => {
                setSteps(new Array(16).fill(false));
                seq.events = new Array(16).fill({ notes: [] });
              }}
            />
            <DeleteButton onClick={() => remove(sound.name)}>D</DeleteButton>
          </div>
        )}
      </div>
    </>
  );
};

export default DrumRack;
