import { useEffect, useState, useRef } from "react";
const VerticalLineAnimator = ({position}) => {
    // const [position, setPosition] = useState(0); // Initial horizontal position of the line at 0%

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         // Dynamically update position to create a moving effect from left to right
    //         setPosition(prevPosition => prevPosition >= 100 ? 0 : prevPosition + 10);
    //     }, 1000); // Update position every second

    //     return () => clearInterval(intervalId); // Clean up the interval on component unmount
    // }, []);

    return (
        <>
            <div className="absolute left-0 transform -translate-x-1/2 w-0.5 h-full bg-black"
                style={{
                    left: `${Math.round(position) === 0 ? -1 : position}%`, // Use left to move the line horizontally
                    // transition: 'left 0.5s ease-in-out' // Smooth transition for the movement
                    transform: `translateX(-${Math.round(position) === 100 ? -1 : 0}px)`
                }}>
            </div>
        </>
    );
}

export default VerticalLineAnimator;