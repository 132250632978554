import { IconSelector } from "@tabler/icons-react";
import React, { useEffect } from "react";

const Select = ({
  className,
  options = [],
  value,
  label = "",
  hasEmpty = true,
  border = "",
  bgColor = "bg-grey-500",
  required = false,
  placeholder,
  disabled = false,
  onChange,
}) => {
  useEffect(() => {
    if (!hasEmpty && options.length > 0 && !value && onChange) {
      onChange(options[0].value);
    }
  }, [hasEmpty, options, value, onChange]);

  return (
    <div className="relative w-full">
      {label && (
        <span className={`text-small font-semibold text-black-700`}>
          {label}
        </span>
      )}
      <IconSelector size={20} className="absolute right-3 bottom-3"/>
      <select
        className={`flex items-center text-grey-100 w-full rounded-md p-[11px] text-sm text-bold ${border} ${bgColor} ${className} appearance-none`}
        disabled={disabled}
        required={required}
        color="blue"
        onChange={(e) => onChange && onChange(e.target.value)}
      >
        {hasEmpty && <option>{placeholder || `Select ${label}`}</option>}
        {options.map((option, key) => (
          <option
            disabled={option.disabled}
            key={key}
            className="p-2 capitalize "
            value={option.value}
            selected={!!value && value === option.value}
          >
            {option.label} {option.disabled && "(Not available at this time)"}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
