import React from "react";
import SingleSample from "./SingleSample";
import { getSamplesList } from "../../services/stepSequencer";
import Button from "../Button/Button";

const samples = getSamplesList();

const SampleSelector = ({ add, cancel }) => {
  return (
    <div className="z-50 p-2 absolute top-0 left-0 w-screen h-screen flex items-center justify-center">
      <div className="max-w-[500px]  border border-gray-500 rounded-xl  flex flex-col bg-white shadow-lg p-6 w-full">
        <div className="flex mb-5 justify-between items-center">
          <h1 className="">Choose a sample</h1>
          <Button onClick={cancel} type="button" className="text-2xl">
            ×
          </Button>
        </div>
        <div className="">
          {samples.map((sample, index) => (
            <SingleSample
              onAdd={() => add({ sample: sample.name })}
              displayName={sample.displayName}
              name={sample.name}
              key={sample.name}
              url={sample.url}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SampleSelector;
