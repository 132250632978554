import React, { useState } from "react";
import { Range, getTrackBackground } from "react-range";

export default function HorizontalSlider({
  className,
  min = 0,
  max = 100,
  value,
  step = 1,
  onChange,
  isHovered,
  isDisabled,
}) {
  //const [values, setValues] = useState([(min + max) / 2]);
  return (
    <div className={`flex w-[130px] justify-center `}>
      <Range
        disabled={isDisabled}
        step={step}
        min={min}
        max={max}
        values={[value]}
        onChange={(values) => onChange(values[0])}
        renderTrack={({ props, children }) => (
          <div
            className="h-[30px] flex w-full"
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
            }}
          >
            <div
              ref={props.ref}
              className="h-[5px] w-full rounded-full"
              style={{
                background: getTrackBackground({
                  values: [value],
                  colors: isDisabled
                    ? ["#ccc", "#ccc"]
                    : [
                        isHovered ? "#fff" : "#212121",
                        isHovered ? "#b0b0b0" : "#c0c0c0",
                      ],
                  min: min,
                  max: max,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            className="h-4 w-4 rounded-full flex justify-center items-center"
            {...props}
            style={{
              ...props.style,
              backgroundColor: isDisabled
                ? "#ccc"
                : isHovered
                ? "#fff"
                : "#212121",
            }}
          ></div>
        )}
      />
    </div>
  );
}
