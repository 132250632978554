import React from "react";
import { MdRefresh } from "react-icons/md";
import Button from "../Button/Button";

const RefreshButton = ({ label = "Clear", size = 20, onClick }) => (
  <>
    <Button
      className="lg:w-[80px] md:border border-gray-600 h-8 lg:h-10 px-2 lg:px-0"
      type="button"
      onClick={onClick}
    >
      <div className="lg:hidden">
        <MdRefresh size={size} />
      </div>
      <span className="lg:block hidden font-bold text-sm lg:text-base">
        {label}
      </span>
    </Button>
  </>
);

export default RefreshButton;
