import React, { useEffect, useState } from "react";
import {
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarLeftExpand,
} from "@tabler/icons-react";
import { useParams } from "react-router-dom";

import DrumRack from "../../components/StepSequencer/DrumRack";
import SampleSelector from "../../components/StepSequencer/SampleSelector";
import PlayerContainer from "../../components/PlayerContainer/PlayerContainer";
import AboutContainer from "../../components/AboutContainer/AboutContainer";
import RefreshButton from "../../components/StepSequencer/RefreshButton";
import DeleteButton from "../../components/StepSequencer/DeleteButton";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import AudioPlayerController from "../../components/AudioPlayerController/AudioPlayerController";
import InstrumentName from "../../components/StepSequencer/InstrumentName";

import { useAuthParam } from "../../hooks/custom-hooks";
import { getSamplesList } from "../../services/stepSequencer";
import { presetSampleData } from "../../services/constants";
import {
  updateTempo,
  startPlaying,
  stopPlaying,
} from "../../services/stepSequencer";
import { getStepSequencerData } from "../../services/players";

const samplesList = getSamplesList();

export default function StepSequencer() {
  const { isAdmin } = useAuthParam();
  const { id } = useParams();

  const [addSampleOpen, setAddSampleOpen] = useState(false);
  const [currentTempo, setCurrentTempo] = useState(120);
  const [masterVolume, setMasterVolume] = useState(1);
  const [samples, setSamples] = useState(samplesList.slice(0, 4));
  const [preset, setPreset] = useState(-1);
  const [presetDataList, setPresetDataList] = useState([])
  const [isPlaying, setPlaying] = useState(false);
  const [isAboutVisible, setIsAboutVisible] = useState(false);
  const [isDrawerOpened, setDrawerOpened] = useState(true);

  const fetchData = async (id) => {
    const { data } = await getStepSequencerData(id);

    setPresetDataList(data.type_config);
  };

  useEffect(() => {
    //Fetch Audio List
    fetchData(id);
  }, [id]);

  useEffect(() => {
    updateTempo(currentTempo);
  }, []);

  useEffect(() => {
    if (preset > -1) {
      const newSamples = [];
      const presetData = presetDataList[preset].data;
      setCurrentTempo(presetDataList[preset].bpm);
      presetData.map((instrument) => {
        newSamples.push({
          ...samplesList[instrument.instrumentId],
          initialNotes: instrument.initialNotes,
          initialVolume: instrument.initialVolume,
        });
      });
      stopPlaying();
      setPlaying(false);
      setSamples(newSamples);
    }
  }, [preset]);

  const play = () => {
    if (isPlaying) {
      stopPlaying();
      setPlaying(false);
    } else {
      startPlaying();
      setPlaying(true);
    }
  };

  const refresh = () => {
    const emptySamplesList = [];
    setSamples(emptySamplesList);
  };

  const activateSample = (sample) => {
    const newSamplesList = [
      ...samples.filter((item) => item.name !== sample),
      ...samplesList.filter((item) => item.name === sample),
    ];
    setSamples(newSamplesList);
  };

  const removeSample = (sample) => {
    const newSamplesList = [...samples.filter((item) => item.name !== sample)];
    setSamples(newSamplesList);
  };

  const onTempoChange = (tempo) => {
    setCurrentTempo(tempo);
    updateTempo(tempo);
  };

  const onHandleVolume = (value) => {
    setMasterVolume(value);
  };

  return (
    <PlayerContainer>
      <Header isAdmin={isAdmin} label="Step Sequencer" />
      <div className="flex h-full">
        <div
          className={`lg:hidden rounded-md transition-all duration-300 bg-grey-400 z-10 ${
            isDrawerOpened ? "w-[140px] lg:w-[200px]" : "w-[40px]"
          }`}
        >
          <div
            className={`flex flex-col transition-all w-[140px] lg:w-[200px] h-full duration-300 ${
              isDrawerOpened
                ? ""
                : "lg:-translate-x-[160px] -translate-x-[100px]"
            }`}
          >
            <div className="flex items-center justify-between ">
              <div className="px-2 flex-1 font-bold flex items-center">
                Track
              </div>
              <Button
                onClick={() => {
                  setDrawerOpened(!isDrawerOpened);
                }}
              >
                {isDrawerOpened ? (
                  <IconLayoutSidebarLeftCollapse />
                ) : (
                  <IconLayoutSidebarLeftExpand />
                )}
              </Button>
            </div>
            <div className="flex-1 flex flex-col gap-0.5">
              {samples.map((sample, index) => (
                <InstrumentName key={"instrument" + index} sample={sample} />
              ))}
            </div>
          </div>
        </div>
        <div className="hidden lg:block">
          <div
            className={`flex flex-col transition-all h-full duration-300 bg-grey-400 rounded-md`}
          >
            <div className="flex items-center justify-between ">
              <div className="px-2 flex-1 font-bold flex items-center py-2">
                Track
              </div>
              {isAdmin && (
                <Button
                  onClick={() => setAddSampleOpen(true)}
                  className="cursor-pointer font-bold flex items-center justify-center text-sm lg:text-base"
                >
                  Add More
                </Button>
              )}
            </div>
            <div className="flex-1  flex flex-col gap-0.5">
              {samples.map((sample, index) => (
                <InstrumentName key={"instrument" + index} sample={sample} />
              ))}
            </div>
          </div>
        </div>
        <div className="h-full px-1 lg:px-4 flex flex-col flex-1">
          <div className="flex justify-around py-2 font-bold text-grey-300">
            {Array(16)
              .fill("")
              .map((item, index) => (
                <div key={"header" + index} className="flex-1 text-center">
                  {index + 1}
                </div>
              ))}
          </div>
          <div className="flex flex-1 flex-col gap-0.5">
            {samples.map((sample) => (
              <DrumRack
                key={sample.name}
                sound={sample}
                remove={removeSample}
                isAdmin={isAdmin}
              />
            ))}
            {isAdmin && (
              <div className="flex justify-between gap-0.5 items-center">
                <RefreshButton onClick={() => refresh()} label={"Clear All"} />
                <DeleteButton
                  label={"Delete All"}
                  className="w-[80px] border border-gray-600 h-8 lg:h-10 font-bold text-sm lg:text-base"
                  type="button"
                  onClick={refresh}
                />
              </div>
            )}
          </div>
          {addSampleOpen && (
            <SampleSelector
              cancel={() => setAddSampleOpen(false)}
              add={(sample) => {
                if (sample.sample) {
                  activateSample(sample.sample);
                }
                setAddSampleOpen(false);
              }}
            />
          )}
        </div>
      </div>
      <AudioPlayerController
        isAboutVisible={isAboutVisible}
        audioIsPlaying={isPlaying}
        onPlayClick={play}
        onAboutClick={() => setIsAboutVisible(!isAboutVisible)}
        volume={masterVolume}
        showVolume={false}
        onVolumeChange={onHandleVolume}
        onTempoChange={onTempoChange}
        handlePresetItem={setPreset}
        currentTempo={currentTempo}
        currentPresetIndex={preset}
        showSave={isAdmin}
        listPreset={presetSampleData.map((item, index) => ({
          name: item.preset_name,
          bpm: item.bpm,
          value: index,
        }))}
      />
      <AboutContainer
        isVisible={isAboutVisible}
        onClose={() => setIsAboutVisible(false)}
      >
        <h2 className="text-2xl font-bold mb-4">Step Sequencer</h2>
        <p className="mb-4">
          We'd love to hear any suggestions you have about how to improve this
          player. To make a suggestion, click here.
        </p>
        <p className="mb-4">Keep humming!</p>
      </AboutContainer>
    </PlayerContainer>
  );
}
