import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import {
  IconVolume,
  IconVolumeOff,
  IconChevronLeft,
} from "@tabler/icons-react";
import HorizontalSlider from "../Slider/HorizontalSlider";

export default function VolumeSliderModal({
  isOpen,
  onClose,
  value,
  onChange,
  isMuted,
  onMute,
}) {
  const [isExpand, setisExpand] = useState(false);

  useEffect(() => {
    setisExpand(isOpen);
  }, [isOpen]);

  const onCloseButton = () => {
    setisExpand(false);
    setTimeout(() => {
      onClose();
    }, 500);
  };
  return (
    <div
      className={`${isExpand ? "w-[270px]" : "w-[36px]"} ${
        isOpen ? "" : "hidden"
      } transition-all duration-500 overflow-hidden flex shadow-md items-center absolute rounded-full top-1/2 -translate-y-1/2 left-[105px] bg-grey-500 p-1`}
      style={{ zIndex: 200 }}
    >
      <Button onClick={() => onMute()} className="!rounded-full mr-3">
        {isMuted ? <IconVolumeOff size={20} /> : <IconVolume size={20} />}
      </Button>
      <HorizontalSlider
        isDisabled={isMuted}
        min={-30}
        max={3}
        value={value}
        onChange={onChange}
      />
      <div className={`text-sm font-bold ml-2 mt-0.5 w-[65px] text-right ${isMuted ? 'text-grey-300' : 'text-grey-100'}`}>{`${value} dB`}</div>
      <Button className="!rounded-full" onClick={() => onCloseButton()}>
        <IconChevronLeft size={20} />
      </Button>
    </div>
  );
}
