import axios from "axios";
// const API_BASE_URL = "https://embed.rilium.com/api";
// const API_BASE_URL = 'http://localhost:5100/api'
const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const apiVersion = process.env.REACT_APP_VERSION;
console.log(apiVersion);
// const apiVersion = "v1";

export const getAudioCompareData = (id) => {
  return axios.get(`${API_BASE_URL}/${apiVersion}/audio-compare/${id}`);
};

export const getEffectPlayerData = async (effectType, id) => {
  return axios.get(
    `${API_BASE_URL}/${apiVersion}/${effectType.toLowerCase()}/${id}`
  );
  // return axios.get(`${API_BASE_URL}/${apiVersion}/effect-player/${id}`);
};

export const getPEQPlayerData = (id) => {
  return axios.get(`${API_BASE_URL}/${apiVersion}/peq/${id}`);
};

export const getStepSequencerData = (id) => {
  return axios.get(`${API_BASE_URL}/${apiVersion}/step-sequencer/${id}`);
};

export const setPEQPlayerData = (id, data) => {
  return axios.put(`${API_BASE_URL}/${apiVersion}/peq/update/${id}`, data);
};

export const setEffectPlayerData = async (effectType, id, data) => {
  return axios.put(
    `${API_BASE_URL}/${apiVersion}/${effectType.toLowerCase()}/update/${id}`,
    data
  );
};
