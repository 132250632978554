import React, { useRef, useEffect } from 'react'

const OutsideClickHandler = ({ className, children, onOutsideClick }) => {
  const wrapperRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        event.preventDefault()
        event.stopPropagation()
        onOutsideClick()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside, {
      passive: true,
    })

    return () => {
      // Unbind the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [onOutsideClick])

  return (
    <div className={className} ref={wrapperRef}>
      {children}
    </div>
  )
}

export default OutsideClickHandler
